<template>
  <div class="tula-footer">
    <Footer :copyright="copyright" :socials="socials" :sections="[]" :logo="null" :mobileApp="null" compact />
  </div>
</template>

<script>
import Footer from '@rr-component-library/footer-basic/src/main';

export default {
  name: 'tulaFooter',

  components: {
    Footer,
  },

  computed: {
    copyright() {
      return 'Серия забегов Тулы и Тульской области! |&nbsp;#тульскиймарафон  |&nbsp;<a class="mail" href="mailto:info@tulamarathon.org">info@tulamarathon.org</a>';
    },
    socials() {
      return [
        {
          icon: 'telegram',
          href: 'https://t.me/tulamarathon',
        },
        {
          icon: 'vkontakte',
          href: 'https://vk.com/tulamarathon',
        },
        {
          icon: 'youtube',
          href: 'https://www.youtube.com/@tulamarathon/',
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.tula-footer {

  ::v-deep {
    .footer {
      background: var(--color-primary);
      color: $color-white-85;
      box-shadow: $elevation-deth-8;
      padding: 0 16px;
    }
    .footer__mobile {
      display: none;
    }

    .footer__bottom {
      border-top: none;
      padding: 20px 0;

      @media (min-width: breakpoint(md)) {
        padding: 25px 0;
      }
    }

    .mail {
      font-weight: 700;
    }

    .footer__social-link,
    .footer__bottom-year,
    .footer__bottom-copyright {
      color: $color-white-85;
    }

    .footer__socials {
      gap: 8px;
    }

    .footer__social {
      padding: 6px;
    }

    .footer__social-link {
      width: 20px;
      height: 20px;
      padding: 0;
    }

    .footer__bottom-copyright a {
      text-decoration: none;
    }
  }
}
</style>
